<template>
  <b-card-code>
    <b-modal id="changePrivateMessage" title="Státusz állítása" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <b-form-group
            label="Megjegyzés"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Kire vonatkozik"
            label-for="target_name"
          >
            <b-form-input
              id="target_name"
              v-model="target_name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('changePrivateMessage')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="updatePrivateMessage()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-6">
        <h4>Archivált külső rendelések számlái</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/order/invoice-list')">Kiállított számlák</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'accepted'"
          class="text-center"
        >
          <feather-icon
            v-if="props.row.accepted == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
        </div>

        <span v-else-if="props.column.field === 'company_price'">
          <p class="text-right">{{ parseInt(props.row.company_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</p>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-if="!props.row.accepted" @click="accepted(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Elfogadás</span>
              </b-dropdown-item>
              <b-dropdown-item v-else @click="accepted(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Elutasítás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDetails(props.row.id)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Részletek</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveInvoice(props.row.id)">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50"
                />
                <span>Visszaállítás</span>
              </b-dropdown-item>
              <!--<b-dropdown-item @click="deleteComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>-->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="order_details" size="lg" ref="order_details" title="Rendelés adatai" hide-footer>
      <div class="row">
        <div class="col-lg-6">
          <p><b>Rendelés adatai</b></p>
          <p>Projekt: <b>{{ selected.project_name }}</b></p>
          <p>Szállítás helye: <b>{{ selected.project_name }}</b></p>
          <p>Megrendelő: <b>{{ selected.creator_name }}</b></p>
          <p>Átvevő: <b>{{ selected.receiver_name }}</b></p>
          <p>Ajánlatot készítette: <b>{{ selected.offer_creator_name }}</b></p>
          <p>Ajánlatot kiadó cég: <b v-if="selected.monolit == 'ME'">Monolit Épszer Kft.</b><b v-else>Monolit Profi Kft.</b></p>
        </div>
        <div class="col-lg-6">
          <p><b>Beszállító adatai</b></p>
          <p>Cégnév: <b>{{ selected.company_name }}</b></p>
          <p>Email: <b>{{ selected.email }}</b></p>
          <p>Kapott ajánlat végösszege: <b>{{ parseInt(selected.company_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></p>
        </div>
        <div class="col-lg-12 mt-2">
          <p class="text-center">
            <button class="btn btn-primary" @click="downloadOffer(selected.id)">Megrendelőlap letöltése</button>
          </p>
        </div>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      selected: {},
      dir: false,
      columns: [
        {
          label: 'Státusz',
          field: 'accepted',
          filterOptions: {
            enabled: false,
            placeholder: 'Státusz',
          },
        },
        {
          label: 'Cég neve',
          field: 'company_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés cégre',
          },
        },
        {
          label: 'Összeg',
          field: 'company_price',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Ajánlatot adta',
          field: 'offer_creator_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Rendelési igényt leadta',
          field: 'creator_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Dátum',
          field: 'order_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés dátumra',
          },
        },
        {
          label: 'Melyik projekthez kellett',
          field: 'project_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés projektre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      title: '',
      target_name: '',
      selected_id: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getInvoices()
  },
  methods: {
    showDetails(id) {
      this.$refs.order_details.show()
      this.rows.forEach(row => {
        if (row.id === id) {
          this.selected = row
        }
      })
    },
    getInvoices() {
      axios({
        method: 'get',
        
        url: 'order-invoices/list/archived',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
    accepted(id) {
      axios({
        method: 'put',
        
        url: `order-invoices/change-status/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getInvoices()
      })
    },
    archiveInvoice(id) {
      axios({
        method: 'put',
        
        url: `order-invoices/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getInvoices()
      })
    },
    downloadOffer(id) {
      axios({
        method: 'post',
        
        url: `order-invoices/download-order/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(response => {
        const mimeType = response.headers['content-type']
        const extension = mimeType.split('/')[1]
        const blob = new Blob([response.data], { type: mimeType })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `megrendelőlap.${extension}`
        link.click()
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
